import {
  getToken, getUserInfo
} from "@/utils/cookies";

export default {
  filters: {
    
  },
  methods: {
    queryPermis(callback, isPermis){
      if(!isPermis){
        callback()
        return
      }
      if(!getToken()){
        this.$message.warning("您还没有登录 请登录");
        setTimeout(()=> {
          this.$router.push({
            path: "/register/login",
          });
        }, 2000)
        return false
      } 
      callback()
    },
    queryPermisUser(callback) {
      if (!getToken()) {
        this.$message.warning("您还没有登录 请登录");
        setTimeout(() => {
          this.$router.push({
            path: "/register/login",
          });
        }, 2000);
        return false;
      }
      if (getUserInfo() && getUserInfo().userType !== "1") {
        this.$message.warning("请申请企业账户登录");
        return false;
      }
      callback();
    },
    downlodaFile ({ data, type, name }) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", `${name}.${type}`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
 
}
