<template>
  <div class="home">
    <div class="banner">
      <el-carousel class="banner-carousel" indicator-position="outside">
        <el-carousel-item v-for="item in dashboardData.photos" :title="item.value3" :key="item.id">
          <img :title="item.value3" :src="item.path" @click="linkToResolve(item.value4)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="new-content">
      <div class="notice">
        <div class="notice-info">
          <div class="notice-info-icon"></div>
          <div class="notice-info-title" @click="
            $router.push({
              path: '/carsite/same-list/noticeInfo/list?index=1',
            })
            ">
            通知:
          </div>
          <div class="notice-info-text" v-if="wrRender">
            <swiper v-if="dashboardData &&
              dashboardData.tongzhi &&
              dashboardData.tongzhi.length
              " class="swiper" :options="noticeSwiperOption" direction="vertical">
              <swiper-slide @click.native="toDetail(item, 'noticeInfo', 1)" v-for="(item, index) in dashboardData.tongzhi"
                :key="index" class="slide">{{ item.name }}</swiper-slide>
            </swiper>
          </div>
        </div>
        <!-- <div
          class="notice-link"
          @click="linkToResolve('http://www.sxsbfqc.cn/')"
        >
          <div class="notice-link-icon"></div>
          <div class="notice-link-text">车主客户业务咨询</div>
        </div> -->
      </div>
      <div class="news-row">
        <new-plate title="业务办理" link="/carsite/same-list/corporateNews/list?index=2">
          <div class="conduct-business">
            <div class="conduct-business-item" @click="scrap">
              <img src="/img/homeIcon/first-occasion.png" />
              <div class="conduct-business-item-text">
                一键报废
                <span class="conduct-business-item-line"></span>
              </div>
            </div>
            <div class="conduct-business-item" @click="jumpNav('/sparePartsMall/sparePartsMall?index=2', false)">
              <img src="/img/homeIcon/spare-parts-sales (2).png" />
              <div class="conduct-business-item-text">
                零配件销售
                <span class="conduct-business-item-line"></span>
              </div>
            </div>
            <div class="conduct-business-item" @click="jumpNav('/recycling-waste/list?index=6', false)">
              <img src="/img/homeIcon/scrap-material-recycling (2).png" />
              <div class="conduct-business-item-text">
                废旧物资回收
                <span class="conduct-business-item-line"></span>
              </div>
            </div>
            <div class="conduct-business-item" @click="jumpNav('/used-car-trading/image-list?index=5', false)">
              <img src="/img/homeIcon/used-car-trad (2).png" />
              <div class="conduct-business-item-text">
                二手车交易
                <span class="conduct-business-item-line"></span>
              </div>
            </div>
          </div>
        </new-plate>
        <new-plate title="公司要闻" link="/carsite/same-list/corporateNews/list?index=2">
          <div class="corporate-exhibit">
            <div class="corporate-big-image" @click="toDetail(dashboardData.qiye[0], 'corporateNews', 2)">
              <img :src="dashboardData.qiye && dashboardData.qiye[0].path" alt="" />
              <div class="corporate-big-tip">
                <div class="big-tip-title">
                  <span class="tip-title-text">{{
                    dashboardData.qiye && dashboardData.qiye[0].name
                  }}</span>
                  <div class="big-tip-more">
                    <span class="tip-title-more">了解更多</span>
                    <span class="tip-title-icon"></span>
                  </div>
                </div>
                <div class="big-tip-wut">
                  {{
                    dashboardData.qiye && dashboardData.qiye[0].abstractTitle
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="corporate-row" style="padding-top: 12px;">
            <div class="corporate-col" @click="toDetail(item, 'corporateNews', 2)" v-for="item in qiye" :key="item.id">
              <div class="corporate-col-image">
                <img :src="item.path" alt="" />
              </div>
              <div class="corporate-col-text">
                {{ item.name }}
              </div>
            </div>
          </div>
        </new-plate>
      </div>
      <div class="news-row">
        <new-plate title="政策法规" className="news-plate-3" link="/news/news?value=1">
          <div class="leftList list3" v-for="item in homeData.policiesList"
            @click="onClickOpen(`/news/newsData?id=${item.id}`)" :key="item.id">
            <div class="leftImg">
              <img :src="item.path" alt="" />
            </div>
            <div class="listRight">
              <p class="p1">{{ item.name }}</p>
              <p class="p2">
                {{ item.abstractTitle }}
              </p>
              <p class="p3">{{ item.createTime }}</p>
            </div>
          </div>
        </new-plate>
        <new-plate className="news-plate-3" title="常见问题" link="/news/news?value=2">
          <div class="RightList" @click="onClickOpen(`/news/newsData?id=${item.id}`)" v-for="item in homeData.problems"
            :key="item.id">
            <div class="RightImg">
              <img :src="item.path" alt="" />
            </div>
            <div class="listRight">
              <p class="p1">
                {{ item.name }}
              </p>
              <p class="p2">
                {{ item.abstractTitle }}
              </p>
            </div>
          </div>
        </new-plate>
        <new-plate className="news-plate-3" title="集团新闻" link="/carsite/same-list/groupNews/list?index=5">
          <div class="leftList list3" @click="toDetail(item, 'groupNews', 5)" v-for="(item, index) in jituan"
            :key="index">
            <div class="leftImg">
              <img :src="item.path" alt="" />
            </div>
            <div class="listRight">
              <p class="p1">{{ item.name }}</p>
              <p class="p2">
                {{ item.abstractTitle }}
              </p>
              <p class="p3">{{ item.createTime }}</p>
            </div>
          </div>
          <!-- <div class="business-center-list">
            <div class="business-center-item">
              <div class="business-center-title">
                <img src="/img/homeIcon/pos.png" alt="" />
                <span class="business-title-text">城北业务部</span>
              </div>
              <div class="business-center-field business-field-main">
                <span class="business-field-title">主要业务：</span>
                <span class="business-field-text">报废汽车回收与拆解、办理正规注销下户手续、废旧金属回收、二手车回收与销售、非金属废料与碎屑回收、加工与销售、金属材料销售。</span>
              </div>
              <div class="business-center-field">
                <span class="business-field-title">地址：</span>
                <span class="business-field-text">西安市未央区石化大道北徐十字向东100米路北</span>
              </div>
              <div class="business-center-field business-field-photo">
                <span class="business-field-title">联系电话：</span>
                <span class="business-field-text">029-84245233</span>
              </div>
            </div>
            <div class="business-center-item">
              <div class="business-center-title">
                <img src="/img/homeIcon/pos.png" alt="" />
                <span class="business-title-text">汽车拆解有限公司城西分部</span>
              </div>
              <div class="business-center-field business-field-main">
                <span class="business-field-title">主要业务：</span>
                <span class="business-field-text">买卖新车、二手车、回收报废汽车。</span>
              </div>
              <div class="business-center-field">
                <span class="business-field-title">地址：</span>
                <span class="business-field-text">西安市未央区天台路118号</span>
              </div>
              <div class="business-center-field business-field-photo">
                <span class="business-field-title">联系电话：</span>
                <span class="business-field-text">18829052629</span>
              </div>
            </div>
          </div> -->
        </new-plate>
      </div>
      <div class="group-video dangjian">
        <new-plate class="group-news" title="党的建设" link="/carsite/same-list/grassrootsDynamic/list?index=3">
          <div class="leftList" @click="toDetail(item, 'groupNews', 5)" v-for="item in dangjian" :key="item.id">
            <div class="leftImg">
              <img :src="item.path" alt="" />
            </div>
            <div class="listRight">
              <p class="p1">{{ item.name }}</p>
              <p class="p2">
                {{ item.abstractTitle }}
              </p>
              <p class="p3">{{ item.createTime }}</p>
            </div>
          </div>
          <!-- <div class="party-building">
            <div
              class="party-building-item"
              @click="toDetail(item, 'groupNews', 5)"
              v-for="(item, index) in jituan"
              :key="index"
            >
              <div class="building-item-label">
                <span class="item-label-date">{{
                  item.createTime.slice(-2)
                }}</span>
                <span class="item-label-month">{{
                  item.createTime.slice(0, 7)
                }}</span>
              </div>
              <div class="building-item-right">
                <div class="item-right-title">{{ item.name }}</div>
                <div class="item-right-text">
                  {{ item.abstractTitle }}
                </div>
              </div>
            </div>
          </div> -->
        </new-plate>
        <!--  link="/carsite/same-list/staffStyle/list?index=4" -->
        <new-plate class="video-center employee-style" title="员工风采">
          <div class="employee-style-title">
            <div class="employee-style-title-text">
              视频中心
            </div>
            <div class="employee-style-link" @click="onClickLink('/carsite/same-list/videoCenter/list?index=4')">
              <span>了解更多</span>
              <img src="/img/homeIcon/more@2x.png" alt="" />
            </div>
          </div>
          <div class="corporate-exhibit">
            <div class="corporate-big-image">
              <el-carousel
                class="corporate-carousel"
                indicator-position="outside"
                @change="changeCarousel"
                :autoplay="!dialogVisible"
                ref="corporateCarousel">
                <el-carousel-item v-for="(item, index) in videoList" :title="item.imgPath" :key="item.id"
                  :name="`${index}`">
                  <img :title="item.value3" :src="item.imgPath" alt="" />
                </el-carousel-item>
              </el-carousel>
              <!-- <img :src="selectVideoConfig.imgPath" alt="" /> -->
              <div class="video-icon" @click="onClickVideoIcon"></div>
            </div>
          </div>
          <div class="employee-style-title">
            <div class="employee-style-title-text">
              文化园地
            </div>
            <div class="employee-style-link" @click="onClickLink('/carsite/same-list/staffStyle/list?index=4')">
              <span>了解更多</span>
              <img src="/img/homeIcon/more@2x.png" alt="" />
            </div>
          </div>
          <div class="corporate-row">
            <!--  @click="onClickVideo(index)"   @click="changeCarousel(index)" active: index === selectCarouselIndex, -->
            <div :class="{
              'corporate-col': true,
            }" v-for="(item, index) in homeList" :key="index">
              <div class="corporate-col-image">
                <img :src="item.path" alt="" />
                <!-- <div class="video-icon"></div> -->
              </div>
            </div>
          </div>
        </new-plate>
      </div>
    </div>
    <!-- <move-qr-code /> -->
    <el-dialog :visible.sync="dialogVisible" width="80%" @opened="dialogOpened" :before-close="handleClose">
      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
        :options="playerOptions(videoPath)">
      </video-player>
    </el-dialog>
  </div>
</template>

<script>
import swiperOption from "./config";
// import MoveQrCode from "@/components/MoveQrCode";
import { permissionMixin } from "@/mixin";
import { mapState } from "vuex";
import NewPlate from "./new-plate.vue";

// utils/index.js
export const getScroll = (vm) => {
  const scrollTop = vm.$route.meta.scrollTop;
  const $content = document.querySelector("#mainContainer");
  if (scrollTop && $content) {
    $content.scrollTop = scrollTop;
  }
};

export default {
  name: "Home",
  components: {
    NewPlate,
    // MoveQrCode,
  },
  mixins: [permissionMixin],
  computed: {
    ...mapState({
      homeData: (state) => {
        return state.home.homeData;
      },
      hotNews: (state) => {
        state.home.hotNews = state.home.hotNews.map((item) => {
          return {
            ...item,
            selected: false,
            timeIcon: "/img/shijian.png",
            carHref: "/img/lianjie.png",
            expertsPeople: "/img/denglu.png",
          };
        });
        return state.home.hotNews;
      },
      dashboardData: (state) => {
        return state.home.dashboardData;
      },
      selectVideoConfig: (state) => {
        return state.home.selectVideoConfig;
      },
      videoList: (state) => {
        return state.home.videoList;
      },
      qiye: (state) => {
        return state.home.dashboardData.qiye
          ? state.home.dashboardData.qiye.splice(1, 3)
          : [];
      },
      jituan: (state) => {
        let { jituan } = state.home ? state.home.dashboardData : {};
        if (Array.isArray(jituan) && jituan.length > 11) {
          return jituan.splice(0, 2);
        } else {
          return jituan;
        }
      },
      dangjian: (state) => {
        let { dangjian } = state.home ? state.home.dashboardData : {};
        if (Array.isArray(dangjian) && dangjian.length > 3) {
          return dangjian.splice(0, 3);
        } else {
          return dangjian;
        }
      },
      loading: (state) => {
        return state.loading;
      },
      homeList: state => {
				return state.sameList.homeList;
			},
    }),
  },
  data() {
    return {
      hotConsultFigure: {
        timer: null,
        count: 0,
        lastCount: 0,
      },
      selectCarouselIndex: 0,
      noneBtnImg: false,
      complaSuggesVisible: false,
      imageUrl: "",
      videoPath: "",
      fileList: [],
      limitCountImg: 3,
      complaSuggesForm: {
        SuggestionsType: 0,
        CreatorUserId: 0,
        Title: "",
        SuggestionsToFiles: [],
        Content: "",
      },
      videoCenterConfig: {
        // imgSrc: this.dashboardData.jituan[0].path,
        active: 0,
      },
      dialogVisible: false,
      wrRender: false,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      ...swiperOption,
      move: {
        boyX: 0,
        boyY: 0,
        speedX: 40,
        speedY: 40,
        mobilityX: 0,
        mobilityY: 0,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    window.clearInterval(this.hotConsultFigure.timer);
    this.hotConsultFigure.timer = null;
  },
  activated() {
    // 保持滚动条
    getScroll(this);
    this.wrRender = true;
  },
  deactivated() {
    this.wrRender = false;
  },
  methods: {
    changeCarousel(index) {
      this.selectCarouselIndex = index
      if(this.videoList[index]){
        this.videoPath = this.videoList[index].path
      }
    },
    onClickLink(link) {
			this.$router.push({
				path: link,
			});
		},
    jumpNav(pathType, isPermis) {
      this.queryPermis(() => {
        let routeUrl = this.$router.resolve({
          path: pathType,
          query: {
            id: 96,
          },
        });
        window.open(routeUrl.href, "_blank");
      }, isPermis);
    },
    linkToResolve(path) {
      window.open(path, "_blank");
    },
    jumpNavThree() {
      let routeUrl = this.$router.resolve({
        path: "/home/governmentSubsidies",
        query: {
          id: 96,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    jumpNavFour() {
      let routeUrl = this.$router.resolve({
        path: "/home/oldCarCalculation",
        query: {
          id: 96,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    scrap() {
      // this.queryPermis(() => {
      this.$router.push({
        path: "/scrapGuide/scrapGuide",
      });
      // }, true);
    },
    onClickOpen(url) {
      let routeUrl = this.$router.resolve({
        path: url,
      });
      window.open(routeUrl.href, "_blank");
    },
    fetch() {
      // this.$store.dispatch("home/queryDashboardData");
      this.$store.dispatch("home/queryHomeData").then(() => {
        this.autoShufflingFigure();
      });
      this.$store.dispatch(`sameList/queryHomeSearchList`, {
        PageSize: 3,
        PageIndex: 1,
        WebSiteType: 4
      })
    },
    autoShufflingFigure() {
      if (this.hotNews && this.hotNews.length > 0) {
        this.hotConsultFigure.timer = setInterval(() => {
          if (this.hotConsultFigure.count >= this.hotNews.length - 1) {
            this.hotConsultFigure.count = -1;
          }
          this.hotConsultFigure.count++;
          this.$store.commit("home/SET_HOTNEWS", {
            data: this.hotNews,
            count: this.hotConsultFigure.count,
            lastCount: this.hotConsultFigure.lastCount,
          });
          this.hotConsultFigure.lastCount = this.hotConsultFigure.count;
        }, 1500);
      }
    },
    onClickStaffStyle(event) {
      let id = event.target.dataset ? event.target.dataset.id : "";
      id && this.toDetail({ id }, "staffStyle", 4);
    },
    toDetail(selectItem, pageCode, index) {
      this.$router.push({
        path: `/carsite/same-list/${pageCode}/detail`,
        query: {
          id: selectItem.id,
          index,
          pageCode,
        },
      });
    },
    onClickVideoIcon() {
      this.dialogVisible = true;
    },
    dialogOpened() {
      this.$refs.videoPlayer.player.paused() &&
        this.$refs.videoPlayer.player.play();
    },
    onClickVideo(index) {
      this.selectCarouselIndex = index
      // this.$refs.corporateCarousel.setActiveItem(index)
      // this.$store.commit("home/updateState", {
      //   selectVideoConfig: item,
      // });
    },
    playerOptions(srcUrl) {
      return {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "9:4", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: srcUrl,
          },
          // {
          //   type: 'application/x-mpegURL',
          //   src: this.playUrl
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      };
    },
    handleClose(done) {
      this.$refs.videoPlayer.player.pause();
      done();
      // this.$confirm('确认关闭？')
      // 	.then(_ => {
      // 		done();
      // 	})
      // 	.catch(_ => {});
    },
    handleAvatarSuccess(res, file) {
      this.complaSuggesForm.SuggestionsToFiles.push(res.result);
    },
    handleAvatarPreview(res, file) {
      this.fileList.push(file);
    },
    handleAvatarSuccessChange(file, fileList) {
      this.noneBtnImg = fileList.length >= this.limitCountImg;
    },
    showComplaSuggesVisible(value) {
      this.queryPermis(() => {
        this.complaSuggesVisible = !this.complaSuggesVisible;
      }, true);
    },
    hideComplaSuggesVisible(value) {
      this.complaSuggesVisible = false;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    suggesFormSubmit(file) {
      this.$refs["complaSuggesForm"].validate((valid) => {
        // if (this.complaSuggesForm.SuggestionsToFiles.length <= 0) {
        //   this.$message.warning("请上传反馈图片");
        //   return;
        // }
        if (
          this.complaSuggesForm.SuggestionsToFiles.length > 0 &&
          this.complaSuggesForm.Title !== "" &&
          this.complaSuggesForm.Content !== ""
        ) {
          this.$store
            .dispatch("home/createSuggestions", this.complaSuggesForm)
            .then(() => {
              this.$message.success("反馈成功");
              this.hideComplaSuggesVisible();
            });
        }
      });
    },
    popoverPublic(file) {
      this.complaSuggesForm = {
        SuggestionsType: 0,
        Title: "",
        SuggestionsToFiles: [],
        Content: "",
      };
      this.fileList = [];
      this.$refs["complaSuggesForm"].resetField &&
        this.$refs["complaSuggesForm"].resetField();
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  font-family: "Microsoft YaHei", "微软雅黑" !important;
}

.general-wrap {
  width: 93%;
  max-width: 1200px;
  margin: 0 auto;
}

.banner {
  position: relative;

  .bg {
    height: 580px;
    width: 100%;
    object-fit: cover;
    // position: absolute;
    // left: 0;
    // top: 0;
    // z-index: 1;
  }

  .banner-carousel {
    width: 100%;
    height: 375px;
    /* no */
    position: relative;

    /deep/.el-carousel__container {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }

    /deep/.el-carousel__indicators--outside {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 48px;

      /* no */
      .el-carousel__button {
        height: 5px;
        /* no */
      }
    }
  }
}

@fontColor: rgb(3, 46, 129);
@hoverFontColor: #032e81;

.lineClamp(@num: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
}

.new-content {
  width: 93%;
  max-width: 1200px;
  margin: 0 auto;

  a {
    color: inherit;
    text-decoration: none;
    font-family: "微软雅黑";
  }

  .corporate-exhibit {
    .corporate-carousel {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .corporate-big-image {
      width: 100%;
      // height: 281px;
      // background-image: url(/img/homeIcon/3@1x.png);
      background-size: 100% 100%;
      position: relative;
      color: #ffffff;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }

      .corporate-big-tip {
        position: absolute;
        left: 0%;
        bottom: 0%;
        width: 100%;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 20px;
        padding-bottom: 20px;
        background: rgba(159, 153, 148, 0.8);
        padding-bottom: 20px;
        box-sizing: border-box;
      }

      .tip-title-text {
        font-size: 18px;
      }

      .big-tip-wut {
        margin-top: 20px;
        line-height: 24px;
        font-size: 14px;
      }

      .big-tip-title {
        display: flex;
        justify-content: space-between;

        .big-tip-more {
          width: 120px;
          cursor: pointer;

          .tip-title-icon {
            width: 18px;
            height: 18px;
            margin-left: 4px;
            display: inline-block;
            vertical-align: middle;
            background-image: url(/img/homeIcon/more-icon.png);
            background-size: contain;
          }
        }

        .tip-title-more {
          display: inline-block;
          font-size: 14px;
          color: #fff;
          vertical-align: middle;
        }
      }
    }
  }

  .corporate-row {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .corporate-col {
      overflow: hidden;
      width: 30%;
      font-size: 14px;
      line-height: 30px;

      .corporate-col-text {
        .lineClamp;
        cursor: pointer;
      }

      &:hover {
        .corporate-col-text {
          color: @fontColor;
        }
      }

      .corporate-col-image {
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .conduct-business {
    width: 100%;

    .conduct-business-item {
      width: 100%;
      height: 100px;
      margin-top: 40px;
      position: relative;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      .conduct-business-item-text {
        width: 180px;
        text-align: center;
        position: absolute;
        right: 12%;
        top: 25%;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #032e81;
      }

      .conduct-business-item-line {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        margin-top: 8px;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 52%;
          width: 50px;
          height: 4px;
          transform: translateX(-50%);
          background: #ff6600;
          border-radius: 2px;
        }
      }

      >img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .business-center-list {
    width: 100%;
    overflow: hidden;

    .business-center-item {
      width: 100%;
      color: #333333;
      font-size: 16px;
      overflow: hidden;
      line-height: 34px;
    }

    .business-center-title {
      padding-bottom: 6px;

      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;
      }

      .business-title-text {
        font-size: 21px;
        font-weight: 500;
        font-family: Source Han Sans CN;
        vertical-align: middle;
        padding-left: 10px;
      }
    }

    .business-center-field {
      vertical-align: middle;
    }

    .business-field-title,
    .business-field-text {
      vertical-align: middle;
    }

    .business-field-main {
      .business-field-text {
        color: #032e81;
        font-size: 18px;
        font-weight: 500;
        font-family: SourceHanSansCN-Regular;
      }
    }

    .business-field-photo {
      .business-field-text {
        color: #ff6600;
        font-size: 24px;
        font-style: oblique;
        font-family: YouSheBiaoTiHei;
        font-weight: 500;
      }
    }
  }

  .leftList {
    overflow: hidden;
    margin-bottom: 30px;
    &.list3 {
      margin-bottom: 20px;
    }

    .leftImg {
      width: 165px;
      height: 100px;

      .img1 {
        width: 100%;
        object-fit: cover;
      }

      >img {
        width: 100%;
        height: 100%;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .leftImg,
    .listRight {
      float: left;
    }

    .listRight {
      width: calc(100% - 175px);
      margin-left: 10px;
      cursor: pointer;

      .p1 {
        cursor: pointer;
        font-size: 18px;
        // font-family: SourceHanSansCN-Bold;
        font-weight: 500;
        color: #333333;
        margin-bottom: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: #29ac79;
        }
      }

      .p2 {
        font-size: 14px;
        // font-family: SourceHanSansCN-Normal;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin-bottom: 1px;
        height: 43px;
        overflow: hidden;
        /*自动隐藏文字*/
        text-overflow: ellipsis;
        /*文字隐藏后添加省略号*/
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /*想要显示的行数*/
        -webkit-box-orient: vertical;
      }

      .p3 {
        font-size: 14px;
        // font-family: SourceHanSansCN-Bold;
        font-weight: 400;
        color: #999999;
        text-align: right;
      }
    }
  }

  .RightList {
    display: flex;
    margin-bottom: 33px;

    .RightImg {
      width: 41.7px;
      height: 44.2px;

      img {
        width: 41.7px;
        height: 44.2px;
        object-fit: cover;
      }
    }

    .listRight {
      margin-left: 21px;

      .p1 {
        cursor: pointer;
        font-size: 18px;
        // font-family: SourceHanSansCN-Regular;
        font-weight: 500;
        color: #333333;
        margin-bottom: 7px;

        &:hover {
          color: #29ac79;
        }
      }

      .p2 {
        width: 389px;
        height: 22px;
        font-size: 14px;
        // font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        overflow: hidden;
        /*自动隐藏文字*/
        text-overflow: ellipsis;
        /*文字隐藏后添加省略号*/
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /*想要显示的行数*/
        -webkit-box-orient: vertical;
      }
    }
  }

  .party-building {
    .party-building-item {
      border-bottom: 1px dashed rgb(206, 206, 206);
      padding-bottom: 20px;
      padding-top: 20px;
      overflow: hidden;

      &:nth-child(1) {
        padding-top: 0;
      }

      .building-item-label {
        width: 13%;
        background: rgb(3, 46, 129);
        color: #ffffff;
        padding: 4px 1%;
        text-align: center;
        display: inline-block;

        .item-label-date {
          font-size: 30px;
          padding-bottom: 3px;
          padding-top: 6px;
          display: block;
          border-bottom: 1px solid #fff;
          /* no */
        }

        .item-label-month {
          display: block;
          margin-top: 2px;
          font-size: 16px;
          line-height: 30px;
        }
      }

      .building-item-right {
        width: 78%;
        float: right;
        cursor: pointer;

        .item-right-title {
          font-size: 18px;
          color: rgb(51, 51, 51);
        }

        .item-right-text {
          font-size: 14px;
          color: #999999;
          line-height: 24px;
          margin-top: 18px;
          .lineClamp;
        }

        &:hover {
          .item-right-title {
            color: @hoverFontColor;
          }
        }
      }
    }
  }

  .staff-style-plate {
    padding-top: 80px;
  }

  .staff-style {
    .news--content {
      width: 100%;
    }

    .staff-style-col {
      float: left;
      width: 280px;
      /* no */
      margin-right: 25px;

      /* no */
      .staff-style-image {
        height: 186px;
      }

      img {
        width: 100%;
        height: 100%;
        border: 0;
        display: block;
      }

      .staff-style-text {
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        .lineClamp(1);
        padding: 2px;
        cursor: pointer;

        &:hover {
          color: @fontColor;
        }
      }
    }
  }

  .group-video {
    width: 100%;
    margin-top: 35px;
    overflow: hidden;

    .group-news {
      width: 46%;
      float: left;

      .group-news-list {
        overflow: hidden;
      }

      .news-item-lable {
        float: left;
        width: 79%;
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .news-item-month {
        float: right;
        width: 20%;
        font-size: 16px;
        color: #333333;
        text-align: right;
      }

      .group-news-item {
        overflow: hidden;
        margin-top: 18px;
        cursor: pointer;

        &:nth-child(1) {
          margin-top: 0;
        }

        &:hover {
          .news-item-lable {
            color: @hoverFontColor;
          }
        }
      }
    }

    .video-center {
      width: 46%;
      padding-left: 6%;

      .corporate-exhibit {
        .video-icon {
          cursor: pointer;
        }
      }

      .video-icon {
        display: inline-block;
        background-image: url(/img/homeIcon/playicon@2x.png);
        background-size: 100% 100%;
        position: absolute;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%, -50%);
      }

      .corporate-col-image {
        .video-icon {
          width: 42px;
          height: 42px;
          background-image: url(/img/homeIcon/playSmall@2x.png);
        }
      }

      .corporate-big-image,
      .corporate-col {
        position: relative;
      }

      /deep/.el-carousel__indicators--outside {
        display: none;
      }

      .corporate-col {
        border: 1px solid transparent;

        &.active {
          border: 1px solid #ff6600;
        }
      }
    }

    &.dangjian {
      .leftImg {
        width: 200px;
        height: 130px;
      }

      .listRight {
        width: calc(100% - 221px);
      }
    }
  }

  .notice {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 10px;
    overflow: hidden;

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .notice-info-icon {
      width: 30px;
      height: 27px;
      line-height: 27px;
      background-image: url(/img/homeIcon/info@2x.png);
      background-size: 100% 100%;
      display: inline-block;
      vertical-align: middle;
    }

    .notice-info,
    .notice-info-icon,
    .notice-info-title,
    .notice-info-text {
      float: left;
    }

    .notice-info-title,
    .notice-info-text {
      color: #ff6600;
      height: 27px;
      line-height: 27px;
      font-size: 18px;
      cursor: pointer;
      vertical-align: middle;
    }

    .notice-info-text {
      line-height: 29px;
    }

    .notice-info-title {
      font-size: 24px;
      padding-left: 10px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .notice-link-icon {
      width: 24px;
      height: 22px;
      background-image: url(/img/homeIcon/pc@2x.png);
      background-size: 100% 100%;
      display: inline-block;
      vertical-align: middle;
    }

    .notice-link-text {
      display: inline-block;
      vertical-align: middle;
      color: @hoverFontColor;
      font-size: 18px;
      font-weight: 600;
      padding-left: 6px;
    }

    .notice-link {
      cursor: pointer;
      float: right;
    }
  }

  .top-swiper {
    width: 100%;
    height: 300px;
    overflow: hidden;

    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }

  .thumbs-swiper {
    width: 100%;
    height: 100px;
    overflow: hidden;

    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }

  .news-row {
    width: 100%;
    overflow-x: hidden;
    margin-top: 35px;

    .news-plate {
      width: 46%;
      float: left;

      &:nth-child(2) {
        padding-left: 8%;
      }
    }

    .news-plate-3 {
      width: calc(97% / 3);

      &:nth-child(n + 2) {
        padding-left: 1%;
      }
    }
  }
}

.employee-style{
  /deep/.news-title{
    margin-bottom: 5px;
  }
  .employee-style-title{
    display: flex;
    justify-content: space-between;
		color: #666666;
		cursor: pointer;
    padding-bottom: 4px;
    padding-top: 4px;
    .employee-style-title-text{
      line-height: 26px;
      font-size: 18px;
      font-weight: bold;
    }

		img {
			width: 22px;
			height: 22px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 9px;
		}

		span {
			display: inline-block;
			font-size: 14px;
			vertical-align: middle;
			line-height: 26px;
			color: rgb(102, 102, 102);
		}

		&:hover {
			span {
				color: @hoverFontColor;
			}
		}
  }
}

.link {
  padding: 44px 17.5% 50px 17.5%;
  background-image: url(/img/linkBg.png);
  background-size: 100% 100%;
  background-color: #ebf4fa;

  .linkTop {
    margin-bottom: 15px;

    .p1 {
      font-size: 24px;
      // font-family: SourceHanSansCN-Light;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      margin-bottom: 5px;
    }

    .p2 {
      font-size: 14px;
      // font-family: SourceHanSansCN;
      font-weight: 300;
      color: #6a7b8c;
      line-height: 28px;
      margin-bottom: 5px;
    }

    .p3 {
      width: 20px;
      height: 3px;
      background: linear-gradient(0deg, #181a7d, #30c9cd, #5be196);
    }
  }

  .qiye {
    margin-top: 45px;
    overflow: hidden;

    .qiyeBox-item {
      cursor: pointer;
    }

    .qiyeBox {
      text-align: center;
      width: 93.8%;
      display: flex;
      justify-content: space-around;

      .qiyeBox-homeImg {
        width: 62px;
        height: 62px;
        margin: 0 auto;

        // >img{
        //   width: 50%;
        //   height: 100%;
        // }
        >.wuliu {
          width: 62px;
          height: 62px;
        }

        // >.weiyuanhui{
        //   width: 70%;
        //   height: 100%;
        // }
      }

      .qiyeBox-text {
        font-size: 18px;
        // font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
        padding-top: 30px;
      }
    }

    .qiyeBox-item,
    .qiye-page,
    .qiyeBox {
      float: left;
    }

    .qiye-page {
      width: 3%;
    }

    .zuo {
      width: 24px;
      height: 45px;
      margin-top: 20px;
    }

    .you {
      width: 24px;
      height: 45px;
      margin-top: 20px;
    }

    // .qiyeImg {
    //   width: 54px;
    //   height: 39px;
    //   object-fit: cover;
    //   margin-bottom: 20px;
    // }
  }
}

/deep/.el-form-item__error {
  left: -50px;
}

.piao-fu {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  transition-property: left, top;
  transition-duration: 1s, 1s;
  transition-timing-function: linear;

  .fu-code {
    width: 160px;
    height: 160px;

    a {
      display: block;
    }

    img {
      width: 160px;
      height: 160px;
    }
  }

  .fu-close {
    width: 100%;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    .icon {
      color: #fff;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
    }
  }
}
</style>
