export default {
	noticeSwiperOption: {
		direction: 'vertical',
		observer: true,	// 改变swiper的样式或者修改swiper的子元素时，自动初始化swiper
		observeParents: true, // 当swiper的祖先元素变化时，swiper更新
		observeSlideChildren: true,	// 子slide更新时，swiper更新
		//显示分页
		pagination: {
			el: ".swiper-pagination",
		},
		//自动轮播
		autoplay: {
			// 轮播切换时间
			delay: 2000,
			//当用户滑动图片后继续自动轮播
			disableOnInteraction: false,
		},
		//开启循环
		loop: true
	},
	staffSwiperOption: {
		// slidesPerView: 4.5,
		// //自动轮播
		// autoplay: {
		// 	// 轮播切换时间
		// 	delay: 3000,
		// 	//当用户滑动图片后继续自动轮播
		// 	disableOnInteraction: false,
		// },
		// //开启循环
		// loop: true
	},
}