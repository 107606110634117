<template>
	<div :class="`news-plate ${className}`">
		<div class="news-title">
			<div class="news-title-text">
				{{ title }}
			</div>
			<div class="news-title-link" v-if="link" @click="onClickLink">
				<span>了解更多</span>
				<img src="/img/homeIcon/more@2x.png" alt="" />
			</div>
		</div>
		<div class="news--content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: "newPlate",
	props: {
		className: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		link: {
			type: String,
			default: "",
		},
	},
	data() {
		return {};
	},
	created() { },
	methods: {
		onClickLink() {
			this.$router.push({
				path: this.link,
			});
			// this.link && window.open(routeUrl.href, "_blank");
		}
	},
};
</script>

<style lang="less" scoped>
@hoverFontColor: #032E81;

.news-plate {
	overflow: hidden;

	.news-title {
		width: 100%;
		line-height: 30px;
		padding-bottom: 18px;
		margin-bottom: 20px;
		border-bottom: 1px solid rgb(3, 46, 129);
		overflow: hidden;
		box-sizing: border-box;
	}

	.news--content {
		overflow: hidden;
	}

	.news-title-text {
		float: left;
		font-size: 24px;
		padding-left: 10px;
		font-weight: bold;
		border-left: 3px solid #022a7a;
	}

	.news-title-link {
		float: right;
		color: #666666;
		cursor: pointer;

		img {
			width: 22px;
			height: 22px;
			vertical-align: middle;
			display: inline-block;
			margin-left: 9px;
		}

		span {
			display: inline-block;
			font-size: 14px;
			vertical-align: middle;
			line-height: 26px;
			color: rgb(102, 102, 102);
		}

		&:hover {
			span {
				color: @hoverFontColor;
			}
		}
	}
}</style>
